import React from 'react'
import { Layout, Seo } from '../components'
import { injectIntl, useIntl } from 'gatsby-plugin-intl'

import {
    IntroSubpage,
    ForDoctorSectionWithBullutes,
    Features,
    ContactForm,
    GreyBackgroundInformation,
    WhyUsSegments,
} from '../sections/'

const ForDoctor = () => {
    const intl = useIntl()
    const t = x => {
        return intl.formatMessage({ id: x })
    }

    const IntroSubpageProps = {
        bg: '#E8FBF8',
        decorationsColor: ['#3D278A', '#04968B', '#51E2C9'],
        title: t('home_section_colorful_segments_title'),
        description: t('for_doctor_intro_message'),
    }

    return (
        <Layout>
            <Seo
                title={t('for_doctor_seo_title')}
                description={t('for_doctor_seo_description')}
            />
            <IntroSubpage {...IntroSubpageProps} />
            <ForDoctorSectionWithBullutes />
            <GreyBackgroundInformation />
            <Features />
            <WhyUsSegments />
            <ContactForm />
        </Layout>
    )
}

export default injectIntl(ForDoctor)
